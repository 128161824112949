import React from 'react'
import {
  Box, Container, Grid, Link, Typography, useMediaQuery,
} from '@mui/material'
import theme from '../../../../themes/theme'
import MainButton from '../../../../components/Button/mainButton/MainButton'
import MosaicImage from './MosaicImage'

const OurServices = () => {
  const matches = useMediaQuery('(max-width:600px)')

  return (
    <Box id='servicios'>
      <Grid
        container
        alignItems='center'
        sx={{
          padding: !matches ? '1rem 5rem' : '0rem 0rem 1rem',
          background: theme.palette.primary.main,
        }}
      >
        <Grid item xs={12} md={5} xl={6}>
          <MosaicImage />
        </Grid>
        <Grid
          item
          xs={12}
          md={7}
          xl={6}
          sx={{ padding: !matches ? '0rem 5rem' : 'auto' }}
        >
          <Container>
            <Box>
              <Typography
                color={theme.palette.primary.light}
                lineHeight={!matches ? '1.75rem' : '2rem'}
                variant='h3'
              >
                Nuestros servicios especializados abarcan todas las etapas del
                proceso de perforación y producción
              </Typography>
              <Typography
                color={theme.palette.primary.light}
                lineHeight='1.5rem'
                fontWeight={500}
                sx={{
                  mt: 2,
                }}
              >
                Somos excelencia para tus operaciones de perforación y
                producción de petróleo y gas. Permítenos potenciar tu negocio y
                llevarlo a nuevos horizontes energéticos.
              </Typography>
            </Box>
            <Link
              href='#contacto'
              sx={{
                color: 'primary',
                display: 'block',
                my: 2,
              }}
              underline='none'
              to='#contacto'
            >
              <MainButton
                sx={{
                  mt: 2,
                  mb: 4,
                  width: !matches ? '16.68rem' : '100%',
                }}
              >
                Pon en marcha tu proyecto
              </MainButton>
            </Link>
          </Container>
        </Grid>
      </Grid>
    </Box>
  )
}

export default OurServices
