import React from 'react'
import {
  Box, Container, Grid, Typography, useMediaQuery,
} from '@mui/material'
import theme from '../../../../themes/theme'
import formas from '../../../../assets/img/formas.svg'

const OptimizationProcesses = () => {
  const matches = useMediaQuery('(max-width:800px)')

  return (
    <Container maxWidth='xl'>
      <Box sx={{ px: !matches ? 3 : '' }}>
        <Grid
          container
          sx={{ px: !matches ? 3 : '', height: !matches ? '80vh' : '' }}
          flexDirection={!matches ? 'row-reverse' : 'row'}
          alignItems='center'
        >
          <Grid item xs={12} md={7} sx={{ height: '13.43rem', my: !matches ? '' : 6 }}>
            <img
              style={{
                position: 'absolute',
                right: 0,
                width: !matches ? '36.68rem' : '17.87rem',
                marginTop: !matches ? '-10rem' : '',
              }}
              alt='bamba'
              src={formas}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <Box>
              <Typography
                data-testid='title-section-products'
                fontSize='1.75rem'
                variant='h3'
              >
                <Typography
                  sx={{
                    color: theme.palette.secondary.main,
                  }}
                  component='span'
                  fontSize='1.75rem'
                  variant='span'
                >
                  &nbsp;Optimizamos&nbsp;
                </Typography>
                los
                <Typography
                  sx={{
                    color: theme.palette.primary.main,
                  }}
                  fontSize='1.75rem'
                  variant='span'
                >
                  &nbsp;procesos de procura
                </Typography>
              </Typography>
              <Box
                mt={2}
                sx={{
                  height: '2.5px',
                  backgroundColor: theme.palette.secondary.main,
                  width: '7.31rem',
                }}
              />
            </Box>
            <Box mt={2} mb={6}>
              <Typography>
                Cada proyecto que abordamos es una oportunidad para dejar una
                huella positiva en la comunidad y en el entorno en el que
                trabajamos. Estamos dedicados a restaurar la calidad de los
                proyectos y contribuir al bienestar de las personas a través de
                nuestras infraestructuras bien planificadas y ejecutadas.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
}

export default OptimizationProcesses
