import { createTheme } from '@mui/material/styles'
import Sora from '../assets/fonts/Sora-Regular.ttf'
import { colors } from '../constants/colors'

const theme = createTheme({
  palette: {
    primary: {
      light: colors.default,
      main: colors.primaryMain,
    },
    secondary: {
      main: colors.secondaryMain,
    },
    background: {
      default: colors.default,
      secondary: colors.backgroundSecondary,
    },
    common: {
      black: colors.commonBlack,
    },
    text: {
      light: colors.backgroundDefault,
      primary: colors.textPrimary,
      secondary: colors.textSecondary,
    },
    success: {
      main: colors.successMain,
    },
    error: {
      main: colors.textError,
    },
  },
  typography: {
    fontFamily: ['Sora'].join(','),
    fontSize: 16,
    h1: {
      fontSize: '2.10rem',
      fontWeight: '800',
      lineHeight: '2.5rem',
      '@media (min-width:600px)': {
        fontSize: '3rem',
        lineHeight: '3.75rem',
      },
    },
    h2: {
      fontSize: '2rem',
      fontWeight: '600',
      lineHeight: '2.5rem',
      '@media (min-width:600px)': {
        fontSize: '2.25rem',
        lineHeight: '3.125rem',
      },
    },
    h3: {
      fontSize: '1.12rem',
      fontWeight: '600',
      lineHeight: '1.85rem',
      '@media (min-width:600px)': {
        fontSize: '1.5rem',
        lineHeight: '1.87rem',
      },
    },
    h4: {
      fontSize: '0.875rem',
      fontWeight: '600',
      lineHeight: '1.05rem',
      '@media (min-width:600px)': {
        fontSize: '1.65rem',
        lineHeight: '3.125rem',
      },
    },
    h5: {
      fontSize: '1rem',
      fontWeight: '300',
      lineHeight: '1.5rem',
    },
    body1: {
      fontSize: '0.875rem',
      fontWeight: '300',
      lineHeight: '1.25rem',
      '@media (min-width:600px)': {
        fontSize: '1rem',
        lineHeight: '1.5rem',
      },
    },
    body2: {
      fontSize: '1.125rem',
      fontWeight: '300',
      lineHeight: '1.25rem',
      '@media (min-width:600px)': {
        fontSize: '1.25rem',
        lineHeight: '1.5rem',
      },
    },
    description: {
      fontSize: '0.875rem',
      fontWeight: '300',
      lineHeight: '1.5rem',
      '@media (min-width:600px)': {
        lineHeight: '1.375rem',
      },
    },
    subtitle1: {
      color: colors.textSecondary,
      fontSize: '1.5rem',
      fontWeight: '600',
      lineHeight: '2.5rem',
    },
    cardSmallText: {
      fontSize: '0.875rem',
      fontWeight: '300',
      lineHeight: '1.5rem',
    },
    subtitle2: {
      fontSize: '1.5rem',
      fontWeight: '600',
      lineHeight: '2.5rem',
    },
    experienceText: {
      fontSize: '0.875rem',
      fontWeight: '300',
      lineHeight: '1.5rem',
      '@media (min-width:600px)': {
        fontSize: '1.125rem',
        lineHeight: '2.5rem',
      },
    },
    titleSmallCard: {
      fontSize: '1.5rem',
      fontWeight: 600,
      lineHeight: '2.5rem',
    },
    subtitleForm: {
      fontSize: '0.75rem',
      fontWeight: '300',
      lineHeight: '2.5rem',
      '@media (min-width:600px)': {
        fontSize: '1.25rem',
        lineHeight: '2.5rem',
      },
    },
    descriptionFooter: {
      fontSize: '0.938rem',
      fontWeight: '600',
      lineHeight: '1.5rem',
      '@media (min-width:600px)': {
        lineHeight: '1.375rem',
      },
    },
    textTerms: {
      fontSize: '0.875rem',
      lineHeight: '1.375rem',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Sora';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Sora'), local('Sora-Regular'), url(${Sora}) format('truetype');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
})

export default theme
