import React from 'react'
import NavBar from '../components/NavBar/NavBar'
import Home from './Home/Home'

const App = () => (
  <>
    <NavBar />
    <Home />
  </>
)

export default App
