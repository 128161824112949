import React from 'react'
import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  useMediaQuery,
} from '@mui/material'
import tab1 from '../../../../../../assets/img/tab1.svg'

const WellServices = () => {
  const matches = useMediaQuery('(max-width:600px)')

  return (
    <Card
      sx={{
        backgroundColor: 'transparent',
        boxShadow: 'none',
        pt: 3,
        pb: 6,
        mb: 10,
        maxWidth: 'auto',
      }}
    >
      <Grid container alignItems='center' justifyContent='space-between' flexDirection={!matches ? 'row-reverse' : 'column'}>
        <Grid item xs={12} md={6} width='100%'>
          <CardMedia
            sx={{ height: '40vh', marginX: '16px' }}
            image={tab1}
            title='green iguana'
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <CardContent>
            <Typography
              gutterBottom
              variant='h3'
              component='div'
              sx={{
                mb: !matches ? 4 : '',
              }}
            >
              A la vanguardia en soluciones para la industria petrolera en
              Latinoamérica y Europa
            </Typography>
            <Typography variant='body1'>
              Brindamos servicios integrales que abarcan todas las etapas de la
              vida útil de un pozo petrolero. Coiled tubing, wireline,
              slickline, tratamiento químico de fondo desde la perforación
              inicial hasta la producción y mantenimiento continuo.
            </Typography>
          </CardContent>
        </Grid>
      </Grid>
    </Card>
  )
}

export default WellServices
