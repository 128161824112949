import React from 'react'
import { Box } from '@mui/material'
import Header from './components/Header'
import OurFocus from './components/OurFocus'
import FocusCarrousel from './components/FocusCarrousel/FocusCarrousel'
import OurServices from './components/OurServices'
import Services from './components/Services/Services'
import ProductsLine from './components/ProductsLine'
import OptimizationProcesses from './components/OptimizationProcesses'
import DoNotLoseThisChance from './components/DoNotLoseThisChance'
import Contacts from './components/Contacts'
import Footer from './components/Footer/Footer'

const Home = () => (
  <Box>
    <Header />
    <OurFocus />
    <FocusCarrousel />
    <OurServices />
    <Services />
    <ProductsLine />
    <OptimizationProcesses />
    <DoNotLoseThisChance />
    <Contacts />
    <Footer />
  </Box>
)
export default Home
