import React from 'react'
import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  useMediaQuery,
} from '@mui/material'
import tab2 from '../../../../../../assets/img/tab2.jpg'

const Construction = () => {
  const matches = useMediaQuery('(max-width:600px)')

  return (
    <Card
      sx={{
        backgroundColor: 'transparent',
        boxShadow: 'none',
        pt: 3,
        pb: 6,
        mb: 10,
        maxWidth: 'auto',
      }}
    >
      <Grid
        container
        alignItems='center'
        justifyContent='space-between'
        flexDirection={!matches ? 'row-reverse' : 'column'}
      >
        <Grid item xs={12} md={6}>
          <CardMedia
            sx={{ height: '40vh', marginX: '16px' }}
            image={tab2}
            title='green iguana'
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <CardContent>
            <Typography
              gutterBottom
              variant='h3'
              component='div'
              sx={{
                mb: !matches ? 4 : '',
              }}
            >
              Profesionales en construcción, acondicionamiento y recuperación de
              infraestructuras
            </Typography>
            <Typography variant='body1'>
              En cada obra, buscamos superar los estándares de calidad,
              garantizando resultados excepcionales y duraderos. Si buscas un
              equipo confiable y comprometido con la excelencia, estamos aquí
              para hacer realidad tus proyectos más ambiciosos.
            </Typography>
          </CardContent>
        </Grid>
      </Grid>
    </Card>
  )
}

export default Construction
