import React, { useState } from 'react'
import AppBar from '@mui/material/AppBar'
import {
  Box,
  Container,
  Drawer,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Link,
  Typography,
  Toolbar,
  useMediaQuery,
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import logo from '../../assets/img/logo.svg'
import { items } from './items'
import theme from '../../themes/theme'

function NavBar() {
  const matches = useMediaQuery('(max-width:600px)')
  const [mobileOpen, setMobileOpen] = useState(false)
  const [selectedLink, setSelectedLink] = useState(null)

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState)
  }

  return (
    <AppBar
      color='background'
      position='static'
      sx={{
        boxShadow: 'none',
        padding: '0.5rem 0',
        position: 'fixed',
        top: 0,
        zIndex: 2,
      }}
    >
      <Container maxWidth='xl'>
        <Box sx={{ px: !matches ? 3 : '' }}>
          <Toolbar disableGutters>
            <img
              alt='bamba'
              src={logo}
              style={{
                width: !matches ? '220px' : '141px',
                height: !matches ? '78px' : '50px',
              }}
            />
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: 'flex', md: 'none' },
                justifyContent: 'flex-end',
              }}
            >
              <IconButton
                aria-label='account of current user'
                aria-controls='menu-appbar'
                aria-haspopup='true'
                color='inherit'
                onClick={handleDrawerToggle}
                size='large'
              >
                <MenuIcon />
              </IconButton>

              <Drawer
                ModalProps={{
                  keepMounted: true,
                }}
                open={mobileOpen}
                onClick={handleDrawerToggle}
                sx={{
                  display: { xs: 'flex', sm: 'none' },
                  '& .MuiDrawer-paper': {
                    boxSizing: 'border-box',
                    width: '55%',
                    height: '100vh',
                    justifyContent: 'space-between',
                  },
                  flexDirection: 'column',
                }}
                variant='temporary'
              >
                <Box>
                  <Divider />
                  <List
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      flexDirection: 'column',
                      paddingTop: '2rem',
                    }}
                  >
                    {items.map((item) => (
                      <ListItem
                        disablePadding
                        key={item.name}
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <ListItemButton sx={{ textAlign: 'center' }}>
                          <Link
                            href={item.route}
                            onClick={() => setSelectedLink(item.name)}
                            sx={{
                              color: 'primary',
                              display: 'block',
                              paddingY: '0.25rem',
                              my: 2,
                              margin: '0rem 0.438rem',
                              borderBottom:
                                selectedLink === item.name
                                  ? `2px solid ${theme.palette.secondary.main}`
                                  : '',
                            }}
                            underline='none'
                            to={item.route}
                          >
                            {item.name}
                          </Link>
                        </ListItemButton>
                      </ListItem>
                    ))}
                    <img
                      alt='bamba'
                      src={logo}
                      style={{ width: '141px', height: '50px' }}
                    />
                  </List>
                </Box>
                <Grid
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    paddingRight: '.5rem',
                    paddingBottom: '1rem',
                  }}
                >
                  <ArrowBackIcon />
                </Grid>
              </Drawer>
            </Box>
            <Box
              sx={{
                display: { xs: 'none', md: 'flex' },
                flexGrow: 1,
                justifyContent: 'flex-end',
              }}
            >
              {items.map((page) => (
                <Link
                  href={page.route}
                  key={page.name}
                  onClick={() => setSelectedLink(page.name)}
                  sx={{
                    color: 'primary',
                    display: 'block',
                    my: 2,
                    margin: '0rem 1.438rem',
                    borderBottom:
                      selectedLink === page.name
                        ? `2px solid ${theme.palette.secondary.main}`
                        : '',
                  }}
                  underline='none'
                  to={page.route}
                >
                  <Typography variant='h5' lineHeight='2rem'>
                    {page.name}
                  </Typography>
                </Link>
              ))}
            </Box>
          </Toolbar>
        </Box>
      </Container>
    </AppBar>
  )
}

export default NavBar
