/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Grid, useMediaQuery } from '@mui/material'
import camion from '../../../../../assets/img/camion.svg'
import tubo from '../../../../../assets/img/tubo.svg'
import hombre from '../../../../../assets/img/hombre.svg'

const MosaicImage = () => {
  const matches = useMediaQuery('(max-width:600px)')
  const itemData = [
    {
      img: camion,
      margin: 0,
      tile: 'img1',
    },
    {
      img: tubo,
      margin: '2rem',
      tile: 'img2',
    },
    {
      img: hombre,
      margin: 0,
      tile: 'img3',
    },
  ]

  return (
    <Grid container py={4} px={1}>
      {itemData.map((item, index) => (
        <Grid key={index} item xs={4} sx={{ mt: item.margin }}>
          <img
            src={item.img}
            srcSet={item.img}
            alt={item.title}
            height={!matches ? '100%' : '226'}
            style={{
              padding: '0.25rem',
              width: '100%',
            }}
          />
        </Grid>
      ))}
    </Grid>
  )
}

export default MosaicImage
