import React from 'react'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { CardMedia, useMediaQuery } from '@mui/material';
import header1 from '../../../../assets/img/header1.jpg'

const Header = () => {
  const matches = useMediaQuery('(max-width:600px)')

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    speed: 2500,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Slider {...settings}>
      <CardMedia
        component='img'
        sx={{ height: !matches ? '80vh' : '40vh' }}
        image={header1}
        alt='green iguana'
      />
    </Slider>
  )
}

export default Header
