import React from 'react'
import {
  Box, Container, Link, Typography, useMediaQuery,
} from '@mui/material'
import contacto from '../../../../assets/img/contacto.svg'
import MainButton from '../../../../components/Button/mainButton/MainButton'

const DoNotLoseThisChance = () => {
  const matches = useMediaQuery('(max-width:600px)')

  return (
    <Box
      sx={{
        backgroundImage: `url(${contacto})`,
        backgroundAttachment: 'fixed',
        backgroundSize: 'cover',
        backgroundPosition: 'bottom',
        width: '100%',
        height: !matches ? '60vh' : '80vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
        mb: 6,
      }}
    >
      <Container maxWidth='xl'>
        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
          sx={{ px: !matches ? 3 : '' }}
        >
          <Box>
            <Typography
              variant='h2'
              fontWeight={800}
              fontSize='1.5rem'
              lineHeight='1.84rem'
              textAlign='center'
              mb={3}
              sx={{
                maxWidth: '44rem',
                margin: '0 auto',
                color: '#FAFAFA',
              }}
            >
              No pierdas la oportunidad de ganar para el futuro
            </Typography>
            <Typography
              variant='body1'
              fontWeight={500}
              lineHeight='1.84rem'
              textAlign='center'
              sx={{
                maxWidth: '41rem',
                margin: '1rem auto',
                color: '#E4E4E7',
              }}
            >
              Implementamos prácticas responsables y tecnologías innovadoras
              para minimizar el impacto ambiental de nuestras operaciones y
              asegurarnos de que nuestras obras sean socialmente responsables.
            </Typography>
          </Box>
          <Link
            href='#contacto'
            sx={{
              color: 'primary',
              display: 'block',
              my: 2,
              margin: '0rem 1.438rem',
            }}
            underline='none'
            to='#contacto'
          >
            <MainButton
              width={!matches ? '9.68rem' : '100%'}
              sx={{
                mt: 2,
                mb: 4,
                fontWeight: 600,
              }}
            >
              Contáctanos
            </MainButton>
          </Link>
        </Box>
      </Container>
    </Box>
  )
}

export default DoNotLoseThisChance
