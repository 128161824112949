export const getEmailPattern = () => {
  const emailAddressRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  return {
    value: emailAddressRegex,
    message: 'Ingrese un email válido',
  }
}

export const getPhonePattern = () => {
  const phoneRegex = /^(0|\+)[0-9\s]{10,}$/

  return {
    value: phoneRegex,
    message: 'Ingrese un número de teléfono válido (debe comenzar con 0 o + y tener al menos 10 dígitos)',
  }
}
