/* eslint-disable react/no-array-index-key */
import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import {
  Box,
  Container,
  Typography,
  useMediaQuery,
} from '@mui/material'
import carrusel1 from '../../../../assets/img/carrusel_1.svg'
import carrusel2 from '../../../../assets/img/carrusel_2.svg'
import carrusel3 from '../../../../assets/img/carrusel_3.svg'
import carrusel4 from '../../../../assets/img/carrusel_4.svg'
import carrusel5 from '../../../../assets/img/carrusel_5.svg'
import theme from '../../../../themes/theme'

const FocusCarrousel = () => {
  const matches = useMediaQuery('(max-width:600px)')
  const images = [
    { img: carrusel1, text: 'Ingenería de consulta' },
    { img: carrusel2, text: 'Procura y suminstro de equipos industriales' },
    { img: carrusel3, text: 'Servicios de mantenimiento y construcción' },
    { img: carrusel4, text: 'Importación de materias primas ' },
    { img: carrusel5, text: 'Asesoria especializada' },
  ]

  return (
    <Container maxWidth='xl' id='accion'>
      <Box sx={{ px: !matches ? 3 : '', pb: 8 }}>
        <Slider
          arrows={false}
          centerMode
          centerPadding='22px'
          infinite
          speed={500}
          responsive={[
            {
              breakpoint: 800,
              settings: {
                arrows: false,
                centerMode: false,
                slidesToScroll: 1,
                initialSlide: 2,
              },
            },
            {
              breakpoint: 480,
              settings: {
                arrows: false,
                slidesToScroll: 1,
              },
            },
          ]}
          slidesToShow={!matches ? 4 : 1}
          slidesToScroll={1}
        >
          {images.map((item, index) => (
            <Box key={index} width='100%' sx={{ px: !matches ? '1rem' : '0.75rem' }}>
              <Box
                sx={{
                  backgroundImage: `url(${item.img})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  width: '100%',
                  height: !matches ? '23.25rem' : '15.37rem',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'white',
                }}
              >
                <Typography
                  color={theme.palette.primary.light}
                  variant='h3'
                  fontWeight={500}
                  sx={{
                    textAlign: 'center',
                    maxWidth: '13rem',
                  }}
                >
                  {item.text}
                </Typography>
              </Box>
            </Box>
          ))}
        </Slider>
      </Box>
    </Container>
  )
}

export default FocusCarrousel
