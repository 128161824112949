/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react'
import {
  Box, Grid, Link, ListItem, ListItemText, Typography, useMediaQuery,
} from '@mui/material'
import logo from '../../../../assets/img/logo.svg'
import { items } from '../../../../components/NavBar/items'
import theme from '../../../../themes/theme'

const Footer = () => {
  const matches = useMediaQuery('(max-width:600px)')
  const [selectedLink, setSelectedLink] = useState(null)

  return (
    <Box backgroundColor='#F2F2F2' pb={3}>
      <Box display='flex' justifyContent='center' sx={{ pt: 6 }}>
        <img alt='bamba' src={logo} />
      </Box>
      <Grid
        container
        style={{ padding: '1rem', marginBottom: 0 }}
        display='flex'
        flexDirection={!matches ? 'row' : 'column'}
        justifyContent='center'
        alignItems='center'
      >
        {items.map((page, index) => (
          <Grid item px={1} key={index}>
            <Link
              href={page.route}
              onClick={() => setSelectedLink(page.name)}
              sx={{
                color: 'primary',
                display: 'block',
                paddingY: '0.5rem',
                my: 2,
                margin: '0rem 0.438rem',
                borderBottom:
                  selectedLink === page.name
                    ? `2px solid ${theme.palette.secondary.main}`
                    : '',
              }}
              underline='none'
              to={page.route}
            >
              <Typography variant='h5'>{page.name}</Typography>
            </Link>
          </Grid>
        ))}
      </Grid>
      <ListItem>
        <ListItemText
          sx={{ textAlign: 'center', padding: '0.20rem 0' }}
          primary='Copyright © 2023. INCECA'
        />
      </ListItem>
    </Box>
  )
}

export default Footer
